<template>
  <div class="head-bar-container pc">
    <div class="menu-show">
      <ul>
        <li v-show="currentPage != 'home'" class="cursor" @click="goto('/')">
          <a>Home</a>
        </li>
        <li v-show="currentPage == 'home'" class="cursor">
          <a>Home</a>
        </li>
        <li v-show="currentPage == 'home'" class="cursor" @click="goto('/about')">
          <a>About</a>
        </li>
        <li v-show="currentPage == 'home'" class="cursor" @click="goto('/terms-of-services')">
          <a>Terms of services</a>
        </li>
        <li v-show="currentPage == 'home'" class="cursor" @click="goto('/privacy-policy')">
          <a>Privacy policy</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    currentPage: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  methods: {
    gohome () {
      window.location.href = '/'
    },
    goto (path) {
      console.log(this.$route.path);
      this.isOpen = false;
      if (path == '/' && this.$route.path == '/') {
        this.scrollToTop()
        return
      }
      // this.$router.push({ name: where });
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}${path}${fullQueryString}`;

    },
  },
}
</script>
