<template>
  <section
    :class="`common-container wiki-commom-type-${domainConfig['designType']} detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar currentPage="detail" />
    <div class="common" :class="`common_${deviceType}`">
      <div class="wiki-banner" :style="{
         backgroundImage: `url(/background/type-${domainConfig['bg']}.png)`,
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat'
       }">
        <div class="wiki-banner-head">
          Terms of Services
        </div>
      </div>

      <div class="flex-auto common-half-pc ">
        <div class="common-item">
          <div class="common-content">
            Welcome to <strong>{{ domainConfig['IAmURL'] }}</strong> (hereinafter referred to as
            &quot;the
            Website&quot;), your comprehensive resource to discover the lives and ideas of the
            world’s greatest {{ domainConfig['keyword'] }}. This Website is crafted for philosophy
            enthusiasts
            seeking insights into historical and modern philosophical thought. By using the Website,
            you agree to comply with and be bound by the following terms of service. Please read
            these terms carefully, and if you do not agree to them, do not use the Website.
          </div>

          <div class="common-title">
            Terms of Use
          </div>

          <div class="common-content">
            1.1 You must be at least 18 years old or use the Website under the supervision of a
            parent or guardian.
          </div>

          <div class="common-content">
            1.2 You agree not to use the Website for any unlawful or unauthorized activities.
          </div>

          <div class="common-content">
            1.3 You must not interfere with or disrupt the normal operation of the Website.
          </div>

          <div class="common-title">
            Intellectual Property
          </div>

          <div class="common-content">
            2.1 All content on the Website, including but not limited to text, images, graphics, and
            code, is the property of the Website or its content providers and is protected by
            copyright laws.
          </div>

          <div class="common-content">
            2.2 You may not copy, distribute, or otherwise use any content from the Website without
            our express written permission.
          </div>

          <div class="common-title">
            User-Generated Content
          </div>

          <div class="common-content">
            3.1 Any content you post on the Website will be considered non-confidential and
            non-proprietary.
          </div>

          <div class="common-content">
            3.2 You grant the Website a global, perpetual, irrevocable, royalty-free license to use,
            reproduce, modify, publish, and distribute any content you provide.
          </div>

          <div class="common-title">
            Disclaimers
          </div>

          <div class="common-content">
            4.1 The information on the Website is generated by ChatGPT and is for reference only; it
            does not constitute professional advice.
          </div>

          <div class="common-content">
            4.2 The Website does not guarantee the accuracy, completeness, or timeliness of the
            information and is not liable for any loss or damage resulting from reliance on such
            information.
          </div>

          <div class="common-title">
            Termination
          </div>

          <div class="common-content">
            5.1 We reserve the right to terminate or restrict your access to the Website at any
            time, without notice.
          </div>

          <div class="common-title">
            Modification of Terms
          </div>

          <div class="common-content">
            6.1 We reserve the right to modify these terms of service at any time. The modified
            terms will be posted on the Website and will become effective immediately.
          </div>

          <div class="common-content">
            By continuing to use {{ domainConfig['IAmURL'] }}, you acknowledge and accept these
            terms,
            enjoying a rich exploration of philosophy’s most influential figures and concepts.
          </div>
        </div>
        <footer>
          Copyright © 2025 {{ domainConfig['IAmURL'] }}. All Rights Reserved.
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import HeadBar from '@/pages/components/head_pc.vue';

import '@/css/home.scss';

export default {
  name: 'Terms',
  components: {
    HeadBar,
  },
  mixins: [common],
  computed: {
    ...mapGetters([
      "domainConfig"
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>
