<template>
  <component :is="contentComponent" />
</template>

<script>

// 将异步加载改为同步加载
import HomeM from '@/pages/m/home.vue'
import HomePC from '@/pages/pc/home.vue'
import { mapGetters } from 'vuex';

export default {

  data () {
    return {
      contentComponent: HomeM,
    };
  },
  computed: {
    ...mapGetters([
      'deviceType',
      "adsensConfig",
      "domainConfig"
    ]),
    title () {
      return `${this.domainConfig['meta'].title}`
    },
    description () {
      return `${this.domainConfig['meta'].des}`
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          name: 'title',
          content: this.title,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    }
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    // if (this.deviceType === 'desktop' || this.deviceType === 'pc') {
    //   this.contentComponent = HomePC
    // } else {
    //   this.contentComponent = HomeM
    // }

    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = HomePC
      return
    }
    this.contentComponent = HomeM
  },
};
</script>
